







































import {
  Component,
  Prop,
  Vue
} from 'vue-property-decorator';
import StockItemList, {
  IStockItemList_Item
} from '../modules/StockItem/List';

import StockItemGroupList, {
  IStockItemGroupList_Item
} from '../modules/StockItemGroup/List';

@Component
export default class StockItemSelect extends Vue {
  @Prop() readonly value ? : IStockItemList_Item | null
  @Prop(Boolean) readonly disabled ? : boolean
  @Prop(String) readonly invalidFeedback ? : string

  isBusy = false;

  selectedGroup = null as number | null;
  searchQuery = '';

  perPage = 10;
  currentPage = 1;

  stockItems = new StockItemList();
  groups = new StockItemGroupList();

  fields = [
    { key: 'ItemNumber', label: ''                                                },
    { key: 'Name',       label: this.$t('Stock item')                             },
    { key: 'Group',      label: this.$t('Group'),     class: 'display-priority-5' },
    { key: 'UnitPrice',  label: this.$t('Price'),     class: 'display-priority-2' },
  ];

  async fetchStockItems() {
    this.isBusy = true;
    this.stockItems = await StockItemList.Fetch(this.currentPage, this.perPage, this.searchQuery, undefined, this.selectedGroup);
    this.isBusy = false;
  }

  async fetchStockItemGroups() {
    this.isBusy = true;
    this.groups = await StockItemGroupList.FetchAll();
    this.groups.Items = ([{
      Id: null,
      Name: this.$t('All groups')
    }] as IStockItemGroupList_Item[]).concat(this.groups.Items);
    this.isBusy = false;
  }

  resetPageAndReloadStockItems() {
    this.pageSelected(1);
  }

  pageSelected(page: number) {
    this.currentPage = page;
    this.fetchStockItems();
  }

  stockItemSelected(record: IStockItemList_Item, idx: number) {
    (this as any).$bvModal.hide('select-stock-item-modal');
    this.$emit('input', record);
  }

  async mounted() {
    await this.$nextTick();
    await this.fetchStockItemGroups();
  }
}
